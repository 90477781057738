import React from 'react'
import ReactDOM from 'react-dom'
import ObjPath from 'object-path'

import * as Acorn from 'acorn'
import { parse } from '@babel/parser'
import generate from '@babel/generator'
import { generate as generateJs } from 'escodegen'
import { transform as babelTransform } from '@babel/standalone'

import { Output } from '../types'

function isReactNode(node) {
  const type = node.type //"ExpressionStatement"
  const obj = ObjPath.get(node, 'expression.callee.object.name')
  const func = ObjPath.get(node, 'expression.callee.property.name')
  return (
    type === 'ExpressionStatement' &&
    obj === 'React' &&
    func === 'createElement'
  )
}

export function findReactNode(ast) {
  const { body } = ast
  return body.find(isReactNode)
}

type CodeType = 'react' | 'js'

export function createEditor(
  domElement,
  errorElement,
  outputConsole: (output: Output) => void
  // moduleResolver = () => null
) {
  function render(node) {
    ReactDOM.render(node, domElement)
  }

  function renderError(node) {
    ReactDOM.render(node, errorElement)
  }

  // function require(moduleName) {
  //   return moduleResolver(moduleName)
  // }

  function getReactWrapperFunction(code) {
    try {
      // 1. transform code
      const tcode = babelTransform(code, { presets: ['es2015', 'react'] }).code

      // 2. get AST
      const ast = Acorn.parse(tcode, {
        sourceType: 'module',
        ecmaVersion: 6
      })

      // 3. find React.createElement expression in the body of program
      const rnode = findReactNode(ast)

      if (rnode) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const nodeIndex = ast.body.indexOf(rnode)
        // 4. convert the React.createElement invocation to source and remove the trailing semicolon
        const createElSrc = generateJs(rnode).slice(0, -1)
        // 5. transform React.createElement(...) to render(React.createElement(...)),
        // where render is a callback passed from outside
        const renderCallAst = Acorn.parse(`render(${createElSrc})`, {
          ecmaVersion: 6
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        }).body[0]
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ast.body[nodeIndex] = renderCallAst
      }

      // 6. create a new wrapper function with all dependency as parameters
      return new Function('React', 'render', 'require', generateJs(ast))
    } catch (ex) {
      debugger
      // in case of exception render the exception message
      renderError(<pre style={{ color: 'red' }}>{ex.message}</pre>)
    }
  }

  function getPlainJSWrapperFunction(code) {
    try {
      const x = parse(code, {
        errorRecovery: true,
        sourceType: 'module',
        strictMode: true
      })

      // 1. transform code
      const tcode = babelTransform(code, { presets: ['es2015', 'react'] }).code

      // 2. get AST
      const ast = Acorn.parse(tcode, {
        sourceType: 'module',
        ecmaVersion: 6
      })

      const generated = generate(x, {})

      // 6. create a new wrapper function with all dependency as parameters
      return new Function('console', 'require', generated.code)
    } catch (ex) {
      // in case of exception render the exception message
      // renderError(<pre style={{ color: 'red' }}>{ex.message}</pre>)
      outputConsole({
        text: ex.message,
        isError: true
      })
    }
  }

  return {
    // returns transpiled code in a wrapper function which can be invoked later
    compile(code, codeType: CodeType) {
      switch (codeType) {
        case 'react':
          return getReactWrapperFunction(code)
        case 'js':
          return getPlainJSWrapperFunction(code)
      }
    },

    // compiles and invokes the wrapper function
    run(code, codeType: CodeType) {
      try {
        const compiledCode = this.compile(code, codeType)
        if (typeof compiledCode !== 'function') return

        switch (codeType) {
          case 'react':
            compiledCode(React, render, require)
          case 'js':
            compiledCode(
              {
                log: (text: string) => outputConsole({ text, isError: false })
              },
              require
            )
        }
      } catch (ex) {
        // outputConsole(<pre style={{ color: 'red' }}>{ex.message}</pre>)
        outputConsole({
          text: ex.message,
          isError: true
        })
      }
    },

    // just compiles and returns the stringified wrapper function
    getCompiledCode(code, codeType: CodeType) {
      switch (codeType) {
        case 'react':
          return getReactWrapperFunction(code).toString()
        case 'js':
          return getPlainJSWrapperFunction(code).toString()
      }
    }
  }
}
