import React from 'react'
import Footer from './Footer'

const Home = () => {
  return (
    <>
      <div className="bg">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6 logo-container">
              <a href="/">
                <img
                  className="logo"
                  src="/img/constructor-labs-badge.png"
                  width="120"
                  alt="Constructor Labs logo"
                />
              </a>
            </div>
            <div className="col-xs-12 col-md-6 nav-container">
              <nav className="nav justify-content-end">
                <a
                  className="nav-link"
                  href="https://medium.com/constructorlabs"
                >
                  Blog
                </a>
                <a className="nav-link" href="/syllabus">
                  Syllabus
                </a>
                <a className="nav-link btn btn-outline" href="/course">
                  Course
                </a>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="home-heading">
                <h1>12 Week Coding Bootcamp in London</h1>
                <span className="sub-heading">
                  Learn <strong>JavaScript</strong>, <strong>React</strong>,{' '}
                  <strong>Node</strong> and become a software developer
                </span>
              </div>
              <div className="mx-auto hero-buttons">
                <a className="btn-lg btn-outline" href="/course">
                  Course
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fluid-container">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-7">
              <p className="intro">
                Our coding bootcamp is based on over 15 years of combined
                industry experience and focuses on practical skills that will
                enable you to thrive in a technical career. Over 12 weeks you
                will not only learn how to code, but how to write software like
                a professional developer. We will go from fundamentals such as
                command-line tools, version control to deploying your
                application to live servers. Along the way you will learn how to
                pair program, work using Agile methodologies and write code
                using TDD.
              </p>
              <h3>About your instructor</h3>
              <div className="about-dmitri">
                <img
                  className="dmitri-photo"
                  src="/img/dmitri.png"
                  height="125"
                  width="125"
                />
                <p className="dmitri-bio">
                  The courses at Constructor Labs are written and delivered by
                  Dmitri Grabov. He is an experienced developer who has worked
                  with the likes of Bank of America Merrill Lynch, Sainsbury's,
                  Lloyds Bank, The App Business and Badoo. Dmitri has written
                  and delivered courses for Sainsbury's and Code Your Future,
                  and regularly mentors at Codebar. He understands the support
                  beginners need to get started and also how to guide them
                  towards building up the skillset required to get a first job
                  in the industry.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-md-5">
              <blockquote style={{ marginTop: '50px' }}>
                <p className="quote">
                  Dmitri's React and Flux workshop at Sainsbury's was incredibly
                  popular and trained two dozen junior and mid level developers.
                  He knows how to break down complex topics into manageable
                  parts and get students comfortable with new material. I highly
                  recommend Dmitri as a techical mentor and instructor.
                </p>
                <p className="author">
                  <strong>Kim Hartlev</strong> - Chief Technology Officer at
                  Sainsbury's
                </p>
              </blockquote>

              <blockquote>
                <p className="quote">
                  If you're thinking about learning fullstack web development —
                  do join @dmitrigrabov and Constructor Labs initial cohort.
                </p>
                <p className="author">
                  <strong>Desigan Chinniah</strong> - Mozilla &amp; Firefox
                </p>
              </blockquote>

              <blockquote>
                <p className="quote">
                  Love the look of this course. Exactly the practical skills
                  people need for real-world web dev.
                </p>
                <p className="author">
                  <strong>Harry Percival</strong> - Author of{' '}
                  <a href="http://www.obeythetestinggoat.com/">
                    Test-Driven Development with Python
                  </a>
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      <div className="fluid-container">
        <div className="row" style={{ padding: '10px' }}>
          <div className="col-12 col-md-10 offset-md-1">
            <table className="course-table">
              <thead>
                <tr>
                  <th>Course</th>
                  <th>Start date</th>
                  <th>Demo day</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="/course" className="link">
                      Fullstack Web Development with JavaScript
                    </a>
                  </td>
                  <td>3rd&nbsp;Sep&nbsp;2018</td>
                  <td>22nd&nbsp;Nov&nbsp;2018</td>
                  <td>Full</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="fluid-container employer-container">
        <div className="container">
          <div className="row">
            <div className="col-12 employer-heading-container">
              <h3 className="employer-heading">Our graduates work at</h3>
            </div>
            <div className="col-12 employer-list">
              <img
                src="/img/lego-logo.png"
                className="employer-logo"
                alt="Lego logo"
              />

              <img
                src="/img/yld-logo.png"
                className="employer-logo"
                alt="YLD logo"
              />

              <img
                src="/img/verve-logo.png"
                className="employer-logo"
                alt="YLD logo"
              />

              <img
                src="/img/paradino-logo.png"
                className="employer-logo"
                alt="YLD logo"
              />

              <img
                src="/img/lexis-nexis-logo.png"
                className="employer-logo"
                alt="Lexis Nexis logo"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home
