import Editor from './Editor'
import React, { useState } from 'react'
import { Flex } from 'rebass'
import Console from './Console'
import { Exercise } from '../types'

interface Props {
  initialCode: Exercise | undefined
}

const CodeRunner = ({ initialCode }: Props) => {
  const [output, setOutput] = useState({ text: '', isError: false })

  return (
    <Flex
      mx="-5vw"
      sx={{
        border: '1px solid #CCC',
        borderRadius: '3px'
      }}
    >
      <Flex
        sx={{
          p: '3px',
          pr: 1
        }}
        flex="1"
      >
        <Editor setOutput={setOutput} initialCode={initialCode} />
      </Flex>

      <Flex
        flex="1"
        sx={{
          p: '3px',
          pl: 1,
          borderLeft: '1px solid #CCC'
        }}
      >
        <Console output={output} />
      </Flex>
    </Flex>
  )
}

export default CodeRunner
