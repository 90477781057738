import React from 'react'

const Footer = () => {
  return (
    <div className="fluid-container footer">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="footer-text">
              <strong>Constructor Labs</strong> runs a 12 week JavaScript
              bootcamp training the next generation of software developers.
              <br />
              <br />
              <a
                href="https://medium.com/constructorlabs"
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/img/medium.svg"
                  alt="medium logo"
                  className="social-icon"
                  height="20px"
                />{' '}
                @constructorlabs
              </a>
              <br />
              <a
                href="https://twitter.com/constructorlabs"
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/img/twitter.svg"
                  alt="twitter logo"
                  className="social-icon"
                  height="20px"
                />{' '}
                @constructorlabs
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-4"></div>
          <div className="col-xs-12 col-md-4"></div>
        </div>
      </div>
    </div>
  )
}

export default Footer
