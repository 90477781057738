import React, { useCallback, useEffect, useRef, useState } from 'react'
import MonacoEditor from '@monaco-editor/react'
import { createEditor } from '../lib/runner'
import debounce from 'debounce'
import { Exercise, Output } from '../types'

interface Props {
  setOutput: (output: Output) => void
  initialCode: Exercise | undefined
}

const Editor = ({ setOutput, initialCode }: Props) => {
  const editorRef = useRef(null)
  const [runner, setRunner] = useState()
  const [code, setCode] = useState('')

  function handleEditorDidMount(editor) {
    editorRef.current = editor
  }

  function handleChange() {
    setCode(editorRef.current.getValue())
    console.log(editorRef.current.getValue())
  }

  function outputConsole(output: Output) {
    setOutput(output)
  }

  useEffect(() => {
    setRunner(createEditor({}, {}, outputConsole))
  }, [])

  useEffect(() => {
    initialCode?.code && setCode(initialCode.code)
  }, [initialCode])

  const run = useCallback(
    debounce((codeToRun: string) => runner?.run(codeToRun, 'js'), 500, false),
    [runner]
  )

  useEffect(() => {
    run(code, 'js')
  }, [code, run])

  return (
    <MonacoEditor
      height="90px"
      defaultLanguage="javascript"
      defaultValue="// some comment"
      onMount={handleEditorDidMount}
      onChange={handleChange}
      value={code}
      options={{
        minimap: {
          enabled: false
        },
        overviewRulerLanes: 0,
        hideCursorInOverviewRuler: true,
        scrollbar: {
          vertical: 'hidden'
        },
        overviewRulerBorder: false,
        renderLineHighlight: 'none',
        quickSuggestions: false
      }}
    />
  )
}

export default Editor
