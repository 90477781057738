import React from 'react'
import { Flex, Image, Link } from 'rebass'

const Header = () => {
  return (
    <Flex>
      <Flex flex={1} p={2}>
        Nav
      </Flex>
      <Flex flex={1} p={2} justifyContent="flex-end">
        {false ? (
          <>
            <Image
              width="24px"
              height="24px"
              mr={2}
              sx={{ borderRadius: '12px' }}
            />
            <Link
              href="#"
              onClick={event => {
                event.preventDefault()
              }}
            >
              Logout
            </Link>
          </>
        ) : (
          <Link
            href="#"
            onClick={event => {
              event.preventDefault()
            }}
          >
            Login
          </Link>
        )}
      </Flex>
    </Flex>
  )
}

export default Header
