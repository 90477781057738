import React from 'react'
import { Flex } from 'rebass'
import { Output } from '../types'

interface Props {
  output: Output
}

const Console = ({ output }: Props) => {
  const { text, isError } = output

  const content =
    typeof text === 'object' ? JSON.stringify(text, undefined, 2) : text
  return (
    <Flex
      as="pre"
      color={isError ? 'red' : 'black'}
      sx={{
        fontFamily: 'Menlo, Monaco, monospace',
        fontSize: '12px',
        lineHeight: '18px'
      }}
    >
      {content}
    </Flex>
  )
}

export default Console
