import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import fetch from 'cross-fetch'
import Home from './Home'
import Session from './Session'
import '@csstools/normalize.css'
import Course from './Course'
import Syllabus from './Syllabus'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import theme from '../theme'

const App = () => {
  const httpLink = new HttpLink({
    uri: 'https://constructorlabs.com/graphql',
    credentials: 'include',
    fetch
  })

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage?.getItem('auth-session') ?? ''
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'auth-session': token
      }
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  })

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/session/:sessionName" component={Session} />
          <Route exact path="/course" component={Course} />
          <Route exact path="/syllabus" component={Syllabus} />
        </Switch>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App
