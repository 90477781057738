import React from 'react'
import Footer from './Footer'

const Course = () => {
  return (
    <>
      <div className="course-header">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6 logo-container">
              <a href="/">
                <img
                  className="logo"
                  src="/img/constructor-labs-badge.png"
                  width="120"
                  alt="Constructor Labs logo"
                />
              </a>
            </div>
            <div className="col-xs-12 col-md-6 nav-container">
              <nav className="nav justify-content-end">
                <a
                  className="nav-link"
                  href="https://medium.com/constructorlabs"
                >
                  Blog
                </a>
                <a className="nav-link" href="/syllabus">
                  Syllabus
                </a>
                <a className="nav-link btn btn-outline" href="/course">
                  Course
                </a>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h1>
                <span>
                  London's only JavaScript bootcamp taught by professional
                  developers
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4">
            <h2 className="course-heading">
              Fullstack Web Development with JavaScript
            </h2>
          </div>
          <div className="col-12 col-md-7">
            <p>
              <span className="bold">Next start date:</span> 3rd December 2018 -{' '}
              <span className="bold">11 places remaining</span>
            </p>
            <p>
              <span className="bold">Location:</span> Clerkenwell, London
            </p>
            <p>
              <span className="bold">Length:</span> 12 weeks
            </p>
            <p>
              <span className="bold">Hours:</span> 9:30am to 6:00pm Monday to
              Friday with weekend take-home projects
            </p>
            <p>
              <span className="bold">Demo day:</span> 22nd November 2018
            </p>
            <p>
              <span className="bold">Target audience:</span> beginner -
              intermediate
            </p>
            <p>
              <span className="bold">Rating:</span> ★★★★★{' '}
              <a
                className="link"
                href="https://www.switchup.org/bootcamps/constructor-labs#tablist-tab-review"
              >
                view all reviews
              </a>
            </p>
            <p>
              <span className="bold">Median starting salary:</span> £35,000
            </p>
            <p>
              <span className="bold">Fees:</span> £5,000
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <h2 className="course-heading">Who is the course aimed at?</h2>
          </div>
          <div className="col-12 col-md-7">
            <p>
              Our students come from all backgrounds and walks of life. What
              unites them is the desire to change careers and become software
              developers. Rather than just teach how to code, we cover the
              entire software development lifecycle. You will learn create an
              entire application yourself from scratch. We will cover Agile
              Methologies, Pair Programming, Test Driven Development and much
              more. As part of the grand finale, you will present your final
              project to our awesome hiring partners. Our last demo day was
              attended by{' '}
              <a className="link" href="https://www.ft.com">
                FT
              </a>
              ,
              <a className="link" href="https://www.deliveroo.com">
                Deliveroo
              </a>
              ,
              <a className="link" href="https://www.sainsburys.co.uk">
                Sainsbury's
              </a>
              ,
              <a className="link" href="https://nested.com">
                Nested
              </a>
              ,
              <a className="link" href="https://www.lyst.co.uk">
                Lyst
              </a>
              ,
              <a className="link" href="https://www.matchesfashion.com">
                MatchesFashion
              </a>
              ,
              <a className="link" href="https://www.yld.io">
                YLD
              </a>
              and many more companies looking to hire our graduates. You could
              not get a better start to your career.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <h2 className="course-heading">Why JavaScript?</h2>
          </div>
          <div className="col-12 col-md-7">
            <blockquote
              className="twitter-tweet"
              data-conversation="none"
              data-lang="en"
            >
              <p lang="en" dir="ltr">
                And kudos to{' '}
                <a href="https://twitter.com/dmitrigrabov?ref_src=twsrc%5Etfw">
                  @dmitrigrabov
                </a>{' '}
                for teaching the kind of js &amp; nodejs skills that employers
                like{' '}
                <a href="https://twitter.com/FT?ref_src=twsrc%5Etfw">@FT</a> are
                crying out for
              </p>
              &mdash; Rhys Evans (@wheresrhys){' '}
              <a href="https://twitter.com/wheresrhys/status/984789926741970946?ref_src=twsrc%5Etfw">
                April 13, 2018
              </a>
            </blockquote>
            <script
              async
              src="https://platform.twitter.com/widgets.js"
              charSet="utf-8"
            ></script>

            <p>
              There are countless programming languages for developers to choose
              from. Some like Ruby are easier to learn but now face limited
              demand in the industry. We focus specifically on JavaScript along
              with React library and Node framework. JavaScript is in the
              enviable position of being the ONLY programming language that runs
              natively in the browser. As a result, any web application that
              wants to provide users with a rich, interactive experience must
              use it.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <h2 className="course-heading">React and Node</h2>
          </div>
          <div className="col-12 col-md-7">
            <p>
              The React library makes it incredibly easy to create amazing
              interfaces while keeping code well structured, maintainable and
              performant.
            </p>
            <p>
              Node is a framework which allows JavaScript to also be run on the
              server, which allows us to create a full-stack application using a
              single language. This allows developers to not only share code
              between the client and the server, but also makes development much
              easier since we can utilise the same skill set for both parts of
              the application. Historically, software development teams were
              split between front end and back end. Using the latest JavaScript
              technologies companies can now create entire features end-to-end
              using the same developers.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <h2 className="course-heading">The future</h2>
          </div>
          <div className="col-12 col-md-7">
            <p>
              The JavaScript language also keeps evolving. Functionality added
              in ES6, ES7 and ES8 standards have made it easier to write clean
              and robust code which is much simpler to work with. All the
              leading technology companies are now putting JavaScript at the
              center of their software development. If you want to acquire a
              skill set that will provide with a satisfying and future-proof
              career choice then you simply cannot choose a better course to
              make that happen for you.
            </p>
          </div>
          <div
            className="col-12"
            style={{ textAlign: 'center', marginTop: '20px' }}
          >
            <a href="/syllabus" className="calendar-link">
              View Syllabus
            </a>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 offset-1 quote-container">
            <h5 className="quote-heading">"Amazing!" - ★★★★★</h5>
            <p className="quote">
              On joining, I was keen to (1) be able to build my own applications
              front and back (2) learn the 'best' latest technologies and (3)
              enable me to switch into software engineering as a career change.
              On all three counts this course has delivered (I've already been
              offered a job!). So super happy!
            </p>
            <p className="quote-author">
              Alastair -{' '}
              <a
                className="link"
                href="https://www.switchup.org/bootcamps/constructor-labs#tablist-tab-review"
              >
                full review
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 break-one"></div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 offset-1 col-lg-8 offset-lg-2">
            <h2>Your instructors</h2>
            <p>
              We pride ourselves on having the best instructors in the business.
              You will be taught only by instructors who have proven themselves
              both as accomplished developers and capable teachers. Dmitri has
              over 15 years experience so you can be confident you are learning
              from the best.
            </p>
          </div>
          <div className="col-10 offset-1 col-lg-4 offset-lg-4 ">
            <div style={{ textAlign: 'center', margin: '20px' }}>
              <img
                className="dmitri-photo"
                src="/img/dmitri.png"
                height="125"
                width="125"
              />
              <h3>Dmitri Grabov</h3>
            </div>
            <p style={{ textAlign: 'justify' }}>
              Dmitri has over 15 years experience working as a developer. In
              that time he has produced work for Bank of America Merrill Lynch,
              Sainsbury's, Lloyds Bank, The App Business and Badoo as well as a
              number of startups. He has in-depth experience with JavaScript,
              React, Node, SQL and NoSQL databases. He has previously used
              technologies such as C, PHP, Java and ActionScript. Dmitri is
              currently working on book covering React and supporting
              technologies for{' '}
              <a className="link" href="https://www.apress.com/gb">
                Apress
              </a>
            </p>
          </div>
          <div
            className="col-12"
            style={{ textAlign: 'center', marginTop: '20px' }}
          >
            <a href="/syllabus" className="calendar-link">
              View Syllabus
            </a>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-10 offset-1 quote-container">
            <h5 className="quote-heading">
              "A superb course with industry focus" - ★★★★★
            </h5>
            <p className="quote">
              In terms of content, Constructor Labs most impressed me with the
              incorporation of the latest industry trends. The curriculum is "in
              touch" with the industry and is focussed on teaching the most
              sought after technologies and development methods - no short-cuts
              or quick fixes. This "real" industry touch is really inspiring as
              a student, as you have a great insight into what to expect from
              the industry when you graduate and more importantly you feel
              equipped for the challenge.
            </p>
            <p className="quote-author">
              Andrew -{' '}
              <a
                className="link"
                href="https://www.switchup.org/bootcamps/constructor-labs#tablist-tab-review"
              >
                full review
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 break-two"></div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-3 offset-md-1">
            <h2 className="course-heading">How we teach</h2>
          </div>
          <div className="col-12 col-md-7">
            <p>
              Rather than focus on long theoretical lectures, we prefer to get
              stuck in and start writing code as soon as possible. As the course
              progresses, we will start to explore topics in greater depth,
              enabling you to build more elegant solutions to ever greater
              challenges.
            </p>

            <p>
              We introduce each new topic with a brief workshop, where we
              explain how it works, provide a few examples and write some code
              together. After that we will provide you with exercises to
              reinforce your understanding and challenge you to solve some
              trickier problems with your new skills. Every few topics we will
              throw in a small project for you to build. The purpose of this is
              to unify everything you have learned so far and start thinking
              about apps as a whole rather than separate functions. The projects
              are also an opportunity for you to flex your creative muscles -
              not only will you be able to practise your design skills, but you
              will also be able to start using any technologies that we have not
              taught you. In many ways, we want this experience to be
              representative of the real world where you will get agile stories
              to implement and the exact implementation design will be up to
              you.
            </p>

            <p>
              Each project will be followed by a group code review. This will be
              a chance for all the students to compare their approaches and
              learn from each other.
            </p>
          </div>
          <div className="col-12 col-md-4">
            <h2 className="course-heading">Methodologies</h2>
          </div>
          <div className="col-12 col-md-7">
            <h3 className="course-sub-heading">Pair Programming</h3>
            <p>
              Pair Programming will get you used to working with others, much
              like you would in a real job. Working with a partner will also
              encourage you to discuss and explain your code and working. This
              experience will be absolutely crucial for the job inteviews
              following the course and we prefer to start practising early. Most
              importantly you and your partner will support each other on your
              journey - having two pairs of eyes will make it much easier to
              spot an out of place comma or a misspelled variable name. Pair
              programming is also an opportunity to learn from each other and
              discover new ways to solve problems.
            </p>

            <h3 className="course-sub-heading">Test Driven Development</h3>
            <p>
              Test Driven Development helps you to focus on code structure and
              quality from the very beginning. Writing tests is a way of
              ensuring our code does what it is supposed to, and encourages us
              to structure it in small, logical pieces. Having tests in place
              will make it much easier for you to make changes to your code with
              a high degree of confidence you have not broken anything along the
              way.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 offset-1 quote-container">
            <h5 className="quote-heading">
              "Excellent full stack coding bootcamp!" - ★★★★★
            </h5>
            <p className="quote">
              The great thing about Constructor Labs is that it makes coding
              accessible for anyone regardless of background if you enjoy
              problem solving and have a keen interest in learning to code. It's
              been intense and challenging but I've learnt more in the last 10
              weeks than I did in the 10 months leading up to it when I was
              teaching myself to code.
            </p>
            <p className="quote-author">
              Mathilde -{' '}
              <a
                className="link"
                href="https://www.switchup.org/bootcamps/constructor-labs#tablist-tab-review"
              >
                full review
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 break-three"></div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-3 offset-md-1">
            <h2 className="course-heading">Getting first job</h2>
          </div>
          <div className="col-12 col-md-7">
            <h3 className="course-sub-heading">Guest speakers</h3>
            <p>
              Every week we have guest speakers who are either experienced
              engineers or represent some of the companies looking to hire our
              graduates. They provide the perfect opportunity to learn more
              about what they do, how they work and most importantly what they
              look for in new joiners. This experience will help you understand
              more about the work of a software developer and position yourself
              as an ideal candidate.
            </p>

            <h3 className="course-sub-heading">Portfolio</h3>
            <p>
              Most companies we speak are not interested in certificates, they
              want applicants with skills. Every weekend the students at
              Constructor Labs build a project using the skills they have
              learned. At the end of course we encourage students to pick out 3
              or 4 favourite projects to create a portfolio of their best work.
              This will give hiring companies an excellent insight into your
              ability and proof of your skills.
            </p>

            <h3 className="course-sub-heading">Demo day</h3>
            <p>
              As part of the course you will work on a group project. As the
              grand finale to the course we will hold a demo day where you will
              get to present your group project to our many hiring partners.
              This will provide with a first opportunity to make an impression
              with potential employers and start securing interviews.
            </p>

            <h3 className="course-sub-heading">Coaching</h3>
            <p>
              Towards the end of the course we will provide you with plenty of
              information about how to best look for work, how to present your
              portfolio and how to prepare for interviews. We also provide
              coaching session with one of London's top recruitment consultants
              who will help you communicate your strengths. You can see some of
              our application techniques at:
              <a
                className="link"
                href="https://medium.freecodecamp.org/how-to-get-your-first-software-developer-job-da3ca0de5d2"
              >
                How to get your first software developer job
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-12"
            style={{ textAlign: 'center', marginTop: '20px' }}
          >
            <a href="/syllabus" className="calendar-link">
              View Syllabus
            </a>
          </div>
          <div className="col-10 offset-1 quote-container">
            <h5 className="quote-heading">
              "Contemporary Web development skills in a supportive learning
              environment for all" - ★★★★★
            </h5>
            <p className="quote">
              Constructor Labs offers an excellent three-month course in
              full-stack web development with JavaScript. As the curriculum
              progresses, students are taken through the concepts and
              fundamentals of vanilla JavaScript, providing them with a solid
              foundation for understanding the language in the context of
              real-world development and problem-solving.
            </p>
            <p className="quote-author">
              Scott -{' '}
              <a
                className="link"
                href="https://www.switchup.org/bootcamps/constructor-labs#tablist-tab-review"
              >
                full review
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Course
