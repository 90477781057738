import React from 'react'
import Footer from './Footer'

const Syllabus = () => {
  return (
    <>
      <div className="course-header">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6 logo-container">
              <a href="/">
                <img
                  className="logo"
                  src="/img/constructor-labs-badge.png"
                  width="120"
                  alt="Constructor Labs logo"
                />
              </a>
            </div>
            <div className="col-xs-12 col-md-6 nav-container">
              <nav className="nav justify-content-end">
                <a
                  className="nav-link"
                  href="https://medium.com/constructorlabs"
                >
                  Blog
                </a>
                <a className="nav-link" href="/syllabus">
                  Syllabus
                </a>
                <a className="nav-link btn btn-outline" href="/course">
                  Course
                </a>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-12" style={{ textAlign: 'center' }}>
              <h1>
                <span>What you will learn</span>
              </h1>
            </div>
            <div className="col-xs-12 col-md-8 offset-md-2">
              <p>
                Our course is designed around developing practical skills that
                will not only provide you with a solid understanding of
                fundamentals and the ability to build amazing apps, but also the
                skills employers are desperately looking for.
              </p>
              <p>
                A substantial part of the course is dedicated to ensuring our
                students understand how real life software is developed and can
                work in a team. To achieve that, we have plenty of guest
                speakers from great companies to tell you all about their
                experience. We will cover Agile Methodologies such as standups,
                retrospectives and planning. You will learn how to structure a
                real life project, ensure effective team communication and how
                to deliver value to your customers. All these skills will be
                applied during the two group projects you will take part in and
                the main one will be presented to our hiring partners on our
                demo day.
              </p>
              <p>
                You can see the typical course schedule below. We may from time
                to time, update the material to focus more on skills that we and
                our talent partners believe would provide a fuller and more
                rounded skill set.
              </p>
            </div>
            <div className="col-xs-12 col-md-6 offset-md-3">
              <h2>Week 1</h2>
              <ul>
                <li>Command line tools, Git, Github, Developer tools</li>
                <li>Functions, operators and types</li>
                <li>Array methods</li>
                <li>How the web works. Responsive design and accessibility</li>
                <li>Weekend project: personal website</li>
              </ul>

              <h2>Week 2</h2>
              <ul>
                <li>Scope</li>
                <li>Object traversal</li>
                <li>DOM manipulation and Events</li>
                <li>Asynchronous programming. Promises and ajax calls</li>
                <li>Weekend project: Project cinema</li>
              </ul>

              <h2>Week 3</h2>
              <ul>
                <li>Modules and Unit Testing using Jest</li>
                <li>Prototypes, Constructors and OOP. Understanding context</li>
                <li>Pure functions and Closures</li>
                <li>Software development principles</li>
                <li>Weekend project: Pick your own</li>
              </ul>

              <h2>Week 4</h2>
              <ul>
                <li>React intro</li>
                <li>Testing React with Enzyme</li>
                <li>Stateless components and ESLint</li>
                <li>Sass</li>
                <li>Weekend project: React cinema</li>
              </ul>

              <h2>Week 5</h2>
              <ul>
                <li>Node, Express and templating with Handlebars</li>
                <li>Understanding HTTP. RESTful API design. Express routing</li>
                <li>Deployment to Heroku</li>
                <li>Testing Node applications</li>
                <li>Weekend project: Quiz machine</li>
              </ul>

              <h2>Week 6</h2>
              <ul>
                <li>SQL and Databases with PostgreSQL</li>
                <li>Database structure. Joins. Using indexes</li>
                <li>Node and Postgres. Preventing SQL injection</li>
                <li>Transactions</li>
                <li>Weekend project: DeliverEAT</li>
              </ul>

              <h2>Week 7</h2>
              <ul>
                <li>Middleware and Passport</li>
                <li>Authentication</li>
                <li>Understanding browser performance and optimisation</li>
                <li>Weekend project: Resource library back-end</li>
              </ul>

              <h2>Week 8</h2>
              <ul>
                <li>Working with Git branches, merging and pull requests</li>
                <li>Small group project</li>
                <li>Weekend project: Resource library front-end</li>
              </ul>

              <h2>Week 9</h2>
              <ul>
                <li>Core Redux</li>
                <li>Using react-redux</li>
                <li>Weekend project: Expense tracker</li>
              </ul>

              <h2>Week 10-11</h2>
              <ul>
                <li>Main group project</li>
              </ul>

              <h2>Week 12</h2>
              <ul>
                <li>Careers, portfolio, CV writing and interview prep</li>
                <li>Demo day preparation</li>
                <li>Demo day</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Syllabus
