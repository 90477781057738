import React from 'react'
import { Flex, Heading } from 'rebass'
import CodeRunner from './CodeRunner'
import { useParams } from 'react-router'
import { Stream } from '@cloudflare/stream-react'
import Header from './Header'
import { useQuery } from '@apollo/client'
import GET_EXERCISES from '../gql/GET_EXERCISES'
import { ExercisesResult, ExercisesVariables } from '../types'

const Session = () => {
  const { sessionName } = useParams<{ sessionName: string }>()

  const EXERCISE_KEY = 'ex1'

  const { data, loading, error } = useQuery<
    ExercisesResult,
    ExercisesVariables
  >(GET_EXERCISES, {
    variables: {
      keys: [EXERCISE_KEY]
    }
  })

  console.log({ data, loading, error })

  return (
    <Flex flexDirection="column">
      <Header />
      {typeof window !== 'undefined' ? (
        <Stream autoplay controls src="c3f41ed6c6578e25ed1ec1334b7f2b70" />
      ) : null}
      <Flex mx="15vw" mt={80} flexDirection="column">
        <Heading as="h2" mb={80}>
          {sessionName}
        </Heading>
        <CodeRunner initialCode={data?.exercises[0]} />
      </Flex>
    </Flex>
  )
}

export default Session
