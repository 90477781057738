import { gql } from '@apollo/client'

const GET_EXERCISES = gql`
  query($keys: [String]) {
    exercises(where: { key: { _in: $keys } }) {
      id
      key
      code
    }
  }
`

export default GET_EXERCISES
